<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<el-table
			    :data="list"
				:span-method="arraySpanMethod"
			    border
			    style="width: 100%">
			    <el-table-column
			      prop="nianji"
			      label="年级"
				  align="center">
			    </el-table-column>
			    <el-table-column
			      prop="xueke"
			      label="学科"
				  align="center">
			    </el-table-column>
			    <el-table-column
			      prop="t_code"
				  align="center"
			      label="教师编码（饭卡号）">
			    </el-table-column>
				<el-table-column
				  prop="t_name"
				  align="center"
				  label="教师姓名">
				</el-table-column>
				<el-table-column
				  prop="project"
				  align="center"
				  label="处罚项目">
				</el-table-column>
				<el-table-column
				  prop="score"
				  align="center"
				  label="扣分">
				</el-table-column>
				<el-table-column
				  align="center"
				  label="操作">
				  <template slot-scope="scope">
				    <el-button type="text" @click="handleDel(scope.row)">删除</el-button>
				  </template>
				</el-table-column>
			  </el-table>
		</el-card>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			Breadcrumb,
		},
		data() {
			return {
				preUrl: 'jlcf',
				autoRequest: false,
				id:'',
				s_id:'',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				data:{},
				list:[],
				list_length:0,
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			if(this.$route.query.id){
				this.s_id = this.$route.query.s_id
			}
			this.getData()
		},
		computed:{
			
		},
		methods:{
			handleDel(row){
				this.$confirm('是否要删除本条信息？', '提示', {
				  confirmButtonText: '删除',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					this.axios.post('/manage/jlcf/del_item', {
					  id:row.id
					}).then(res => {
						this.$message({
						  message: '删除成功',
						  type: 'success'
						})
					    this.getData()
					})
				})
			},
			getData(){
				this.axios.get('/manage/'+this.preUrl+'/view',{
					params:{
						id:this.id
					}
				}).then(res=>{
					if(res.status){
						this.data = res.data
						this.list = res.data.list
						this.list_length = this.list.length
						// this.list.unshift({nianji:'',xueke:'',t_code:'全体教职工',t_name:'',day:'教务处按模板导入',score:''})
					}
				})
			},
			arraySpanMethod({ row, column, rowIndex, columnIndex }) {
				// if(rowIndex === this.list_length){
				// 	if(columnIndex === 0){
				// 		return [2, 3];
				// 	}
				// }
				// if(rowIndex === this.list_length+2){
				// 	if(columnIndex === 0){
				// 		return [1, 5];
				// 	}
				// 	if(columnIndex === 1){
				// 		return [1, 7];
				// 	}
				// }
				// if(rowIndex === this.list_length+3){
				// 	if(columnIndex === 0){
				// 		return [1, 20];
				// 	}
				// }
			},
		}
	}
</script>

<style>
</style>